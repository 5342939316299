import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../utils/Translations';

class BasicTextInput extends React.Component {

	constructor(props) {
		super(props);

		this.baseScrollHeightLine1 = 0;
		this.baseScrollHeightLineN = 0;
		this.height                = 0;
		this.refEditorContainer    = null;
		this.textarea              = null;
		this.autoGrowBlocked       = false;

		let maxLength = 5000;

		// no default prop here so that we can check the original value in the render method to decide whether or not to set the maxLength attribute on the textarea (hard limit)
		if (this.props.maxLength) {
			maxLength = this.props.maxLength;
		}

		this.state = {
			maxLength:      maxLength,
			remainingChars: maxLength,
			usedChars:      0,
			value:          this.props.value,
		};

		this.detectResize         = this.detectResize.bind(this);
		this.autoGrowTextarea     = this.autoGrowTextarea.bind(this);
		this.cleanInput           = this.cleanInput.bind(this);
		this.getPlainText         = this.getPlainText.bind(this);
		this.getPlainTextFull     = this.getPlainTextFull.bind(this);
		this.handleReturn         = this.handleReturn.bind(this);
		this.onBlur               = this.onBlur.bind(this);
		this.onChange             = this.onChange.bind(this);
		this.onFocus              = this.onFocus.bind(this);
		this.setFocus             = this.setFocus.bind(this);
		this.setValue             = this.setValue.bind(this);
		this.updateRemainingChars = this.updateRemainingChars.bind(this);
		this.updateValue          = this.updateValue.bind(this);
	}

	componentDidMount() {
		this.updateRemainingChars();

		// get scroll height for one and two rows because first line is higher than all additional lines
		this.textarea.rows         = 1;
		this.baseScrollHeightLine1 = this.textarea.scrollHeight;                                    // get height of first row
		this.textarea.rows         = 2;
		this.baseScrollHeightLineN = this.textarea.scrollHeight - this.baseScrollHeightLine1;       // get height of additional row
		this.textarea.rows         = this.props.rows ? this.props.rows : 1;                         // reset to initial rows
	}

	detectResize() {
		const newHeight = this.refEditorContainer.offsetHeight;

		if (this.props.onResize && this.height !== newHeight) {
			this.props.onResize(newHeight);
			this.height = newHeight;
		}
	}

	autoGrowTextarea() {
		if (this.state.value.length < 1 && !this.autoGrowBlocked) {
			this.textarea.rows   = 1;
			this.autoGrowBlocked = true;
			this.onChange();
			return;
		}

		this.autoGrowBlocked = false;

		if (this.props.rowsAutoGrowMax && this.baseScrollHeightLine1) {
			const minRows = this.props.rows ? this.props.rows : 1;
			const maxRows = this.props.rowsAutoGrowMax;

			let rows = 1;
			if (this.textarea.scrollHeight > this.baseScrollHeightLine1) {
				const scrollHeight = this.textarea.scrollHeight - this.baseScrollHeightLine1;
				rows += Math.round(scrollHeight / this.baseScrollHeightLineN);
			}

			this.textarea.rows = Math.min(maxRows, Math.max(minRows, rows));
		}
	}

	cleanInput(ignoreFocus) {
		this.textarea.value = '';

		this.setState({
			value: this.textarea.value,
		}, () => {
			this.detectResize();
		});

		this.updateRemainingChars();
		this.autoGrowTextarea();

		if (!ignoreFocus) {
			this.textarea.focus();
		}
	}

	getPlainText(doNotIgnoreEmptyLines) {
		const result   = [];
		const text     = this.textarea.value;
		const textRows = text.split('\n');

		for (let r = 0; r < textRows.length; r++) {
			if (textRows[r] !== '' || doNotIgnoreEmptyLines || !this.props.ignoreEmptyLines) {
				result.push(textRows[r]);
			}
		}

		return result.join('\n');
	}

	getPlainTextFull(callback, ignoreFocus) {
		if (typeof callback === 'function') {
			const text = this.getPlainText();
			if (text.trim() !== '') {
				callback(text, ignoreFocus);
			}
		}
	}

	handleReturn() {
		this.props.onSubmit();
		this.forceUpdate();
	}

	onBlur() {
		if (typeof this.props.onBlur === 'function') {
			this.props.onBlur();
		}
	}

	onChange(setFocus = false) {
		this.setState({
			value: this.textarea.value,
		}, () => {
			if (typeof this.props.onChange === 'function') {
				this.props.onChange();
			}

			this.detectResize();
			this.updateRemainingChars();
			this.autoGrowTextarea();
			this.detectResize();

			if (setFocus) {
				this.setFocus();
			}
		});
	}

    onFocus() {
        if (typeof this.props.onFocus === 'function') {
            this.props.onFocus();
        }

        // force virtual keyboard on mobile
        if (navigator.virtualKeyboard) {
            navigator.virtualKeyboard.show();
        }
    }

	setFocus() {
		setTimeout(() => this.textarea.focus(), 100);
	}

	setValue(value) {
		this.textarea.value = value;
		this.setState({
			value: value,
		}, () => {
			this.setFocus();
		});
	}

	updateRemainingChars() {
		const plainText = this.getPlainText();

		this.setState({
			remainingChars: this.state.maxLength - plainText.length,
			usedChars:      plainText.length,
		});
	}

	updateValue(value) {
		this.textarea.value = value;

		this.onChange(true);
	}

	render() {
		const textareaProps = {};
		const placeholder   = this.props.textInputPlaceholder;

		if (this.props.readOnly) {
			textareaProps.readOnly = true;
		}

		if (this.props.maxLength) {
			textareaProps.maxLength = this.state.maxLength;
		}

		// if set to null: ignore rows and take textarea's default
		if (this.props.rows) {
			textareaProps.rows = this.props.rows;
		}

		let remainingChars;
		if (this.props.displayRemainingChars) {
			remainingChars = (
				<div className={this.props.remainingCharsClassName}>
					{this.state.remainingChars + " " + Translations.get('Characters')}
				</div>
			);
		}

		let usedChars;
		if (this.props.displayUsedChars) {
			usedChars = (
				<div className={this.props.usedCharsClassName}>
					{"(" + this.state.usedChars + "/" + this.state.maxLength + ")"}
				</div>
			);
		}

		return (
			<div ref={(ref) => this.refEditorContainer = ref}>
				<div className={this.props.editorContainerClassName}>
					<textarea
						className="basic-text-input"
						onChange={this.onChange}
						onBlur={this.onBlur}
						onFocus={this.onFocus}
						placeholder={placeholder}
						{...textareaProps}
						ref={textarea => {
							this.textarea = textarea;
						}}
						defaultValue={this.state.value}
                        inputMode="text"
					/>
					{remainingChars}
					{usedChars}
				</div>
			</div>
		);
	}

}

BasicTextInput.propTypes = {
	onSendTextMessage:        PropTypes.func,
	onSubmit:                 PropTypes.func,
	textInputPlaceholder:     PropTypes.string,
	value:                    PropTypes.string,
	onChange:                 PropTypes.func,
	readOnly:                 PropTypes.bool,
	maxLength:                PropTypes.number,
	onResize:                 PropTypes.func,
	displayRemainingChars:    PropTypes.bool,
	remainingCharsClassName:  PropTypes.string,
	displayUsedChars:         PropTypes.bool,
	usedCharsClassName:       PropTypes.string,
	editorContainerClassName: PropTypes.string,
	ignoreEmptyLines:         PropTypes.bool,
	onBlur:                   PropTypes.func,
	onFocus:                  PropTypes.func,
	rows:                     PropTypes.number,
	rowsAutoGrowMax:          PropTypes.number,
	getMessagePrice:          PropTypes.func,
};

BasicTextInput.defaultProps = {
	ignoreEmptyLines: false,
	rows:             1,
	value:            '',
};

export default BasicTextInput;
